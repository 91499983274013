<template>
  <div class="container-fluid p-0">
    <div class="row justify-content-between align-items-end">
      <div class="col-8">
        <h2 class="fs-5 fw-bold d-inline">Combined Values Calculator</h2>
        <p class="text-gray-500 py-2">
          Select Upper Extremity (UEI) or Lower Extremity (LEI) or Whole Person
          Impairment (WPI) and then enter the values in the A and B boxes.
          Add/Remove the input box as needed with the Input +/- button.
        </p>

        <div class="container-fluid d-flex p-0 my-5 align-items-center">
          <div
            class="d-flex px-3 py-2 justify-content-between align-items-center inputs-section col-3 bg-gray-100 rounded-3 me-5"
          >
            <span>Inputs</span>
            <div>
              <button
                class="btn bg-white text-success btn-sm mx-2"
                @click="addInput"
              >
                <img src="@/assets/svg/plus.svg" alt="add" />
              </button>
              <button class="btn btn-sm bg-white text-danger" @click="delInput">
                <img src="@/assets/svg/minus.svg" alt="delete" />
              </button>
            </div>
          </div>
          <div class="fw-bold">
            <BaseRadioGroup
              v-model="calculation_mode"
              :options="calculationModeOptions"
              :label="`calculation_mode`"
            />
          </div>
        </div>

        <div class="container p-0">
          <div
            class="d-flex flex-wrap mt-5 justify-content-start align-self-end overflow-hidden"
          >
            <div
              v-for="(input, idx) in inputs"
              :key="idx"
              class="d-flex justify-content-start align-items-center my-2"
            >
              <div
                class="w-75"
                :class="
                  idx / 2 !== 0 ? 'append-plus-icon position-relative' : ''
                "
              >
                <div class="input-group">
                  <div class="input-group-text text-gray-600">
                    {{ input.name }}
                  </div>

                  <input
                    v-model="input.value"
                    type="number"
                    class="form-control"
                    placeholder="...%"
                    :aria-label="'Number Value ' + `${input.name}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        Please enter the larger value(s) before the smaller value.
      </div>
      <div class="col-3 min-h-50">
        <div class="card p-2 shadow-sm border-1 border-gray-100 text-center">
          <div
            class="bg-primary px-3 py-2 fw-bold text-white rounded-3 text-uppercase"
          >
            Result
          </div>
          <div class="mt-4 mb-1">
            <p
              v-if="calculation_mode !== 'wpi' && finalTextDisplay"
              class="fw-bolder fs-3 p-0 text-dark"
            >
              {{ finalTextDisplay }}
            </p>
            <p class="fw-bolder fs-3 p-0 text-dark">
              {{ finalWpi || '0' }} % WPI
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import { getCombinedValue } from '@/utils/calculations.js'
export default {
  name: 'ValueCalculator',
  components: { BaseRadioGroup },
  data() {
    return {
      inputs: [
        { name: 'A', value: null },
        { name: 'B', value: null },
      ],

      calculationModeOptions: [
        { label: 'UEI', value: 'uei' },
        { label: 'LEI', value: 'lei' },
        { label: 'WPI', value: 'wpi' },
      ],

      calculation_mode: 'wpi',
    }
  },

  computed: {
    calculatedValue: function () {
      let ary = this.inputs.map((e) => Math.round(e.value))

      return getCombinedValue(ary)
    },

    finalWpi: function () {
      // Return value of combined total and filter it based on the selected mode

      if (this.calculatedValue) {
        let finalDisplay = this.calculatedValue

        if (this.calculation_mode === 'uei') {
          return Math.round(finalDisplay * 0.6)
        }

        if (this.calculation_mode === 'lei') {
          return Math.round(finalDisplay * 0.4)
        }
      }
      return this.calculatedValue
    },

    finalTextDisplay: function () {
      if (this.calculation_mode !== 'wpi' && this.calculatedValue) {
        let finalText = String(this.calculatedValue)
        return finalText + '%' + ' ' + this.calculation_mode.toUpperCase()
      }

      return null
    },
  },

  methods: {
    getAlphabets() {
      /**
       * Return an array of alphabets from A to Z
       */
      // Initialize letters variable
      let letters = []
      // Append each looped letter
      for (let i = 'A'.charCodeAt(0); i <= 'Z'.charCodeAt(0); i++) {
        // Convert number to letter
        letters.push(String.fromCharCode([i]))
      }
      // Return letter
      return letters
    },
    addInput() {
      /**
       * Add an input to the list of available inputs
       * This goes up to the letter
       */
      // Get length of inputs to be used for index of new alphabets
      const LENGTH = this.inputs.length
      // Get alphabets
      const ALPHABETS = this.getAlphabets()

      // Check if current inputs are more than the alphabets
      if (LENGTH >= ALPHABETS.length) {
        // Throw alert to notify the limit of inputs have been reached
        alert("You've reached the maximum number of inputs available.")
        return
      }

      // Push new input
      this.inputs.push({ name: ALPHABETS[LENGTH], value: null })
    },

    delInput() {
      /**
       * Pop latest input from the list of inputs
       */
      this.inputs.length > 2
        ? this.inputs.pop()
        : alert("You can't have less than 2 inputs.")
    },
  },
}
</script>

<style lang="scss" scoped>
.append-plus-icon {
  &::before {
    content: '+';
    color: #c2c2c2;
    display: inline-flex;
    align-self: center;
    position: absolute;
    width: 20px;
    top: 25%;
    left: -20%;
    height: 20px;
  }
}

//placeholder css
input::placeholder {
  color: #c2c2c2;
  text-align: right;
}
</style>
