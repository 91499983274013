<template>
  <div class="container-fluid p-0">
    <div class="row justify-content-between align-items-end">
      <div>
        <h2 class="fs-5 fw-bold d-inline">Apportionment Calculator</h2>
        <p class="text-gray-500 py-2 w-75">
          To calculate the impairment value from the injury, enter the current
          impairment value and the impairment value from the prior
          injury/injuries.
        </p>

        <div class="container-fluid p-0">
          <div class="row mt-5">
            <div class="row d-flex align-items-end col-8">
              <div class="col-4">
                <label class="text-gray-600">Current:</label>
                <div class="input-group">
                  <div class="input-group-text text-gray-600">C</div>

                  <input
                    v-model="apportionment.current"
                    type="number"
                    class="form-control"
                    placeholder="...%"
                    :aria-label="`current value`"
                  />
                </div>
              </div>

              <div class="col-4">
                <label class="text-gray-600">Previous:</label>
                <div class="input-group">
                  <div class="input-group-text text-gray-600">A</div>

                  <input
                    v-model="apportionment.previous"
                    type="number"
                    class="form-control"
                    placeholder="...%"
                    :aria-label="`current value`"
                  />
                </div>
              </div>
            </div>

            <div class="ms-auto col-3">
              <label class="text-primary">Injury Impairment:</label>
              <div class="rounded-2 border-primary d-flex input-group">
                <div
                  class="bg-primary border-primary text-white fw-bold input-group-text px-3"
                >
                  B
                </div>

                <div
                  class="form-control text-center fw-bold fs-3 border-primary"
                >
                  {{ injurtImpairmentResult }} %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApportionmentCalculator',
  components: {},
  data() {
    return {
      apportionment: {
        previous: '',
        current: '',
      },
    }
  },
  computed: {
    injurtImpairmentResult: function () {
      /**
       * Returns the combined values after combining via the formula
       */

      //default value
      let finalAnswer = 0

      // Formula
      // C - A / ( 1 - A ) = B

      //converts to percent
      let C = this.apportionment.current / 100
      let A = this.apportionment.previous / 100

      finalAnswer = (C - A) / (1 - A)

      //convert final answer back to percentage format
      finalAnswer *= 100

      // Return value parsed to float and 2 decimal places

      return Math.round(finalAnswer)
    },
  },

  methods: {
    getAlphabets() {
      /**
       * Return an array of alphabets from A to Z
       */
      // Initialize letters variable
      let letters = []
      // Append each looped letter
      for (let i = 'A'.charCodeAt(0); i <= 'Z'.charCodeAt(0); i++) {
        // Convert number to letter
        letters.push(String.fromCharCode([i]))
      }
      // Return letter
      return letters
    },
    addInput() {
      /**
       * Add an input to the list of available inputs
       * This goes up to the letter
       */
      // Get length of inputs to be used for index of new alphabets
      const LENGTH = this.inputs.length
      // Get alphabets
      const ALPHABETS = this.getAlphabets()

      // Check if current inputs are more than the alphabets
      if (LENGTH >= ALPHABETS.length) {
        // Throw alert to notify the limit of inputs have been reached
        alert("You've reached the maximum number of inputs available.")
        return
      }

      // Push new input
      this.inputs.push({ name: ALPHABETS[LENGTH], value: null })
    },

    delInput() {
      /**
       * Pop latest input from the list of inputs
       */
      this.inputs.length > 2
        ? this.inputs.pop()
        : alert("You can't have less than 2 inputs.")
    },
  },
}
</script>

<style lang="scss" scoped>
.append-plus-icon {
  &::before {
    content: '+';
    color: #c2c2c2;
    display: inline-flex;
    align-self: center;
    position: absolute;
    width: 20px;
    top: 25%;
    left: -20%;
    height: 20px;
  }
}

//placeholder css
input::placeholder {
  color: #c2c2c2;
  text-align: right;
}
</style>
