<template>
  <div class="container-fluid px-5 mt-3">
    <h1 class="mb-5 text-center">Calculators</h1>
    <div>
      <div class="card p-4 mt-3">
        <ValueCalculator />
      </div>
      <div class="card p-4 my-5">
        <ApportionmentCalculator />
      </div>
    </div>
  </div>
</template>

<script>
// import { api } from "@/axios-api";
import { mapState } from 'vuex'
import ValueCalculator from '@/components/Widget/ValueCalculator.vue'
import ApportionmentCalculator from '@/components/Widget/ApportionmentCalculator.vue'

export default {
  name: 'Dashboard',
  components: { ValueCalculator, ApportionmentCalculator },
  computed: mapState(['APIData']),
}
</script>

<style lang="scss"></style>
